import React,{Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './pages/Loading';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = React.lazy(() => import('./pages/Home'))
const About = React.lazy(() => import('./pages/About'))
const Providers = React.lazy(() => import('./pages/Providers'))
const Services = React.lazy(() => import('./pages/Services'))
const Contact = React.lazy(() => import('./pages/Contact'))

const PrimaryCare = React.lazy(() => import('./pages/PrimaryCare'))
const UrgentCare = React.lazy(() => import('./pages/UrgentCare'))
const PhysicalExam = React.lazy(() => import('./pages/PhysicalExam'))
const PediatricsCare = React.lazy(() => import('./pages/PediatricsCare'))
const WomensHealth = React.lazy(() => import('./pages/WomensHealth'))
const Covid19Testing = React.lazy(() => import('./pages/Covid19Testing'))
const Immunization = React.lazy(() => import('./pages/Immunization'))
const PreventativeCare = React.lazy(() => import('./pages/PreventativeCare'))
const MentalHealth = React.lazy(() => import('./pages/MentalHealth'))

const BookAppointment = React.lazy(() => import('./pages/BookAppointment'))

const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsConditions = React.lazy(() => import('./pages/TermsConditions'))
// const Error = React.lazy(() => import('./pages/Error'))


function App() {
  return (
    <>
      <Suspense fallback={<Loading/>}>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/providers" element={<Providers />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact-us" element={<Contact />} />

              <Route path="/primary-care" element={<PrimaryCare />} />
              <Route path="/urgent-care" element={<UrgentCare />} />
              <Route path="/physical-exam" element={<PhysicalExam />} />
              <Route path="/pediatrics-care" element={<PediatricsCare />} />
              <Route path="/womens-health" element={<WomensHealth />} />
              <Route path="/covid-19-testing" element={<Covid19Testing />} />
              <Route path="/immunization" element={<Immunization />} />
              <Route path="/preventative-care" element={<PreventativeCare />} />
              <Route path="/mental-health" element={<MentalHealth />} />

              <Route path="/book-appointment" element={<BookAppointment />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsConditions />} />

              {/* <Route path="*" element={<Error />} /> */}
          </Routes>
          <ToastContainer
              position="bottom-left"
              autoClose={5000}
          />
      </Suspense>
    </>
  );
}

export default App;
